<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-screen">
				<el-form inline size="small">
					<el-form-item :label="$t('teacher.formItem.goodsName')">
						<el-input v-model="screenForm.goods_name" clearable :placeholder="$t('teacher.placeholder.goodsName')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.goodsId')">
						<el-input v-model="screenForm.goods_id" clearable :placeholder="$t('teacher.placeholder.goodsId')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.cate')">
						<el-cascader v-model="cateCheck" class="w_all" :options="cateOption" :show-all-levels="false"
							clearable @change="handleCateChange"
							:props="{expandTrigger:'hover',value:'cate_id',label:'cate_name',children:'children'}">
						</el-cascader>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.student')">
						<el-input v-model="screenForm.student_name" clearable :placeholder="$t('teacher.placeholder.studentName')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.shopName')">
						<el-input v-model="screenForm.store_name" clearable :placeholder="$t('teacher.placeholder.shopName')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.saleStatus')">
						<el-select v-model="screenForm.status" class="w_all" clearable>
							<el-option v-for="(item,index) in saleOption" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.belongClass')">
						<el-select v-model="screenForm.class_id" class="w_all" clearable>
							<el-option v-for="(item,index) in classOption" :key="index" :label="item.name"
								:value="item.class_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.account')">
						<el-input v-model="screenForm.user_name" clearable :placeholder="$t('teacher.placeholder.account')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearch"><i class="el-icon-search"></i></el-button>
						<span class="search-clear" @click="handleClearSearch"><i class="el-icon-brush"></i>{{$t('teacher.actions.clear')}}</span>
					</el-form-item>
				</el-form>
			</div>
			<div class="t-page-table">
				<el-table :data="pageList" size="small">
					<el-table-column :label="$t('teacher.tableHead.goodsId')" prop="goods_id" width="100px"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.goodsName')" min-width="140px">
						<template slot-scope="scope">
							<div class="goods-item" @click="handlePreview(scope.row.goods_id)">
								<el-image :src="scope.row.default_image" class="pic"></el-image>
								<div class="item-box">
									<div class="name">{{scope.row.goods_name}}</div>
									<div class="text">{{$t('teacher.tags.shop')+':'}}{{scope.row.store_name}}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.belongClass')" prop="class_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.shopkeeper')" prop="user_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.goodsStatus')" align="center">
						<template slot-scope="scope">
							{{scope.row.is_violation!==1?scope.row.if_show:3 | statusFilter($t('teacher.option.saleStatus'))}}
						</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.clickCount')" prop="click_count" align="center"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.orderCount')" prop="order_count" align="center"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.price')" prop="price" align="center"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.actions')">
						<template slot-scope="scope">
							<span class="link-item" @click="handlePreview(scope.row.goods_id)">{{$t('teacher.actions.view')}}</span>
							<span class="link-item" v-if="scope.row.if_show ===1 && scope.row.is_violation !== 1" @click="handleOffShelf(scope.row.goods_id)">{{$t('teacher.actions.illegalDelisting')}}</span>
							<span class="link-item" v-if="scope.row.is_violation === 1" @click="handleRecover(scope.row.goods_id)">{{$t('teacher.actions.illegalRelieve')}}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="t-page-bottom">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pageCurr" :page-size="pageSize" layout="total, prev, pager, next"
					:prev-text="$t('teacher.pagination.prevText')" :next-text="$t('teacher.pagination.nextText')" :total="pageTotal">
				</el-pagination>
			</div>
			<el-dialog width="420px" top="10vh" :visible.sync="dialogIframeVisible">
				<iframe class="dialog-iframe" :src="iframePath"></iframe>
			</el-dialog>
		</div>
	</el-scrollbar>
</template>

<script>
	import { categoryShop } from '@/api/seller/category';
	import { fetchClass, getGoodsList, goodsOffShelf, goodsRestore } from '@/api/teacher';
	const defaultForm = {
		goods_name: '',
		goods_id: '',
		cate_id: '',
		student_name: '',
		status: '',
		store_name: '',
		class_id: '',
		user_name: '',
	}
	export default {
		filters: {
			statusFilter(status, option) {
				const statusMap = ['warning', 'success', 'gray', 'danger']
				if (option) {
					let statusText = ''
					option.forEach(item => {
						if (parseInt(item.value) === parseInt(status)) {
							statusText = item.label
						}
					})
					return statusText
				}
				return statusMap[status]
			}
		},
		data() {
			return {
				screenForm: Object.assign({}, defaultForm),
				saleOption: Object.assign([], this.$t('teacher.option.saleStatus')),
				cateCheck: [],
				cateOption: [],
				classOption: [],
				pageList: [],
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
				dialogIframeVisible: false,
				iframePath: '',
			}
		},
		created() {
			this.getCateOption();
			this.getClassOption();
			this.getPageList();
		},
		methods: {
			getCateOption() {
				categoryShop({is_platform: 1}).then(response => {
					this.cateOption = response.data.list;
				})
			},
			getClassOption() {
				let params = {
					page_index: 0,
					page_size: 1000
				}
				fetchClass(params).then(response => {
					this.classOption = response.data.list;
				})
			},
			getPageList() {
				let params = {
					...this.screenForm,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize
				}
				if(this.$route.query.id){
					params.store_id = this.$route.query.id;
				}
				getGoodsList(params).then(response => {
					this.pageList = response.data.list;
					this.pageTotal = parseInt(response.data.count);
				})
			},
			handleCateChange(value){
				this.screenForm.cate_id = value[value.length-1];
			},
			handleSearch() {
				this.pageCurr = 1;
				this.getPageList();
			},
			handleClearSearch() {
				this.screenForm = Object.assign({}, defaultForm)
				this.datetime = '';
				this.pageCurr = 1;
				this.cateCheck = [];
				this.getPageList();
			},
			handleSizeChange(val) {
				this.pageSize = val;
			},
			handleCurrentChange(val) {
				this.pageCurr = val;
				this.getPageList();
			},
			handlePreview(id){
				this.iframePath = this.$itemBasePath+'/m/item/good/'+id;
				this.dialogIframeVisible = true;
			},
			handleOffShelf(id) {
				this.$confirm(this.$t('teacher.confirm.offShelf'), this.$t('teacher.confirm.title'), {
					confirmButtonText: this.$t('teacher.actions.confirm1'),
					cancelButtonText: this.$t('teacher.actions.cancel1'),
					type: 'warning'
				}).then(() => {
					goodsOffShelf({ goods_id: id }).then(() => {
						this.$message.success(this.$t('teacher.successMsg.handle'))
						this.getPageList()
					})
				}).catch(() => {
					this.$message(this.$t('teacher.cancelMsg.handle'))
				})
			},
			handleRecover(id) {
				this.$confirm(this.$t('teacher.confirm.recoverShelves'), this.$t('teacher.confirm.title'), {
					confirmButtonText: this.$t('teacher.actions.confirm1'),
					cancelButtonText: this.$t('teacher.actions.cancel1'),
					type: 'warning'
				}).then(() => {
					goodsRestore({ goods_id: id }).then(() => {
						this.$message.success(this.$t('teacher.successMsg.handle'))
						this.getPageList()
					})
				}).catch(() => {
					this.$message(this.$t('teacher.cancelMsg.handle'))
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;
		.t-page-title {
			padding-bottom: 20px;

			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.t-page-screen {

			// padding-top: 18px;
			// border-top: 1px solid #EBEEF5;
			
			::v-deep .el-form {
				display: flex;
				flex-wrap: wrap;
				.el-form-item {
					width: 320px;
					flex-shrink: 0;
					display: flex;
					padding: 0 9px;
					margin-right: 0;
					margin-bottom: 16px;
					box-sizing: border-box;
					.el-form-item__label {
						min-width: 68px;
					}
					.el-form-item__content {
						flex: 1;
						width: 0;
					}
				}
			}
			.w100 {
				width: 100px;
			}
			.w_all {
				width: 100%;
			}

			.search-clear {
				cursor: pointer;
				margin-left: 12px;

				i {
					margin-right: 4px;
				}
			}
		}

		.t-page-table {
			::v-deep .el-table {
				th.el-table__cell {
					background-color: $--seller-thead-background-1;
				}
			}

			.link-item {
				color: $--seller-link-color;
				cursor: pointer;
				margin: 0 5px;
				&:hover {
					color: $--seller-link-hover;
				}
			}
			.goods-item {
				display: flex;
				cursor: pointer;
				.pic {
					width: 44px;
					height: 44px;
					margin-right: 10px;
				}
				.item-box {
					flex: 1;
					width: 0;
					line-height: 20px;
					.name {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-bottom: 4px;
					}
					.text {
						font-size: 12px;
						color: #999999;
					}
				}
			}
		}

		.t-page-bottom {
			padding: 15px 0;
			text-align: center;
		}
	}
	.dialog-iframe {
		width: 375px;
		height: 667px;
		border: none;
		box-shadow: 0 0 5px rgba(0,0,0,.5);
	}
</style>
